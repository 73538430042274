import * as React from "react"
import ProgressBar from 'react-bootstrap/ProgressBar';

import '../components/progressbar.css';

function Progress({ itemArr }) {
    return (
        <div>
        {itemArr && itemArr.map((link, i) => (
            <React.Fragment key={link.title}>
                <h6 className="fw-600" lang="en">{ link.title }</h6>
                <ProgressBar now={ link.value } children className={ "mb-4 w-" + link.value } />
              {i !== itemArr.length - 1}
            </React.Fragment>
          ))}
      </div>

    );
  }
  
  export default Progress;