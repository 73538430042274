import * as React from "react"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';

import Header from '../components/header'
import StickyNav from '../components/stickynav'
import Progress from '../components/progressbar'
import CardCol from '../components/card'
import Footer from '../components/footer'
// import Modal from '../components/modal'


import "animate.css/animate.min.css";
import '../components/font.css';
import '../components/stylesheet.css';

// import '../components/progressbar.css';


import memoji from "../images/aboutme-memoji.svg"
import omlogo from "../images/1m-gray-scale-logo.svg"
import gtlogo from "../images/gt-gray-scale-logo.svg"
import ruaclogo from "../images/ruac-gray-scale-logo.png"
import card5678 from "../images/5678-card-img.png"
import card1mws from "../images/1mws-card-img.png"
import card1mhd from "../images/homedance-card-img.png"

const itemLink = [
  {text: "학력", destination: "#education",},
  {text: "경력", destination: "#experience",},
  {text: "기술", destination: "#skill",},
  {text: "능력", destination: "#case-study",},
]

const skillListD =[
  {title: "Adobe XD", value: "100",},
  {title: "Illustrator", value: "75",},
  {title: "Photoshop", value: "75",},
  {title: "InDesign", value: "75",},
  {title: "Sketch", value: "100",},
  {title: "Figma", value: "100",},
  {title: "Premiere Pro", value: "75",},
]

const skillListP =[
  {title: "HTML5", value: "100",},
  {title: "CSS3", value: "75",},
  {title: "JavScript", value: "75",},
  {title: "Python", value: "50",},
  {title: "Java", value: "50",},
  {title: "Bootstrap", value: "100",},
  {title: "GitHub", value: "75",},
]

const skillListC =[
  {title: "Zeplin", value: "100",},
  {title: "Notion", value: "100",},
  {title: "Slack", value: "100",},
  {title: "MS Office", value: "75",},
]

const skillListL =[
  {title: "Korean", value: "100",},
  {title: "English", value: "100",},
]

// markup
const IndexPage = () => {
  return (
    <main lang="ko">
      <title>Gina Lee | Portfolio 2022</title>
      <Header />
      <StickyNav href="/" bgColor="white" textColor="black" title="Gina Lee" itemArr={ itemLink }/>
      <div className="scrollspy" id="aboutme"
    data-bs-spy="scroll"
    data-bs-target="#stickynav"
    data-bs-root-margin="0px 0px -40%"
    data-bs-smooth-scroll="true"
    tabindex="0">
        <div className="px-4 pt-4 my-5 text-center" id="top">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
          <h3 className="fw-bold" lang="en">Gina Lee</h3>
          <h1 className="display-3 fw-800 pb-3">미친 센스.</h1>
          <img src={memoji} width="50%"/>
          <h1 className="display-4 fw-800 pt-4 pb-4">빼어난 디자인 능력.</h1>
          <h1 className="display-4 fw-800 pb-4">빈틈없는 기획력.</h1>
          <h1 className="display-4 fw-800 pb-4">게다가 프로그래밍까지.</h1>
          <h1 className="display-4 fw-800 pb-4">어디 한번 알아 볼까요?</h1>
        </AnimationOnScroll>
          
        </div>

        <div className="pt-4 bg-black" id="education">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <div className="p-lg-4 p-3 mt-5 text-white r-24 box-black" id="box">
          <div className="row mx-2 mt-3">
            <div className="col-lg-5 text-start pe-5 mb-4">
              <h5 className="pb-2 fw-700">학력</h5>
              <h4 className="w-100 w-md-75 fw-700">디자인, 프로그래밍, 데이터 등 <br></br><span lang="en">User Experience</span> 전공 이수.</h4>
            </div>
            <div className="col-lg-7 text-start">
              <div className="pb-5">
                <h6 className="fw-700 fc-gray" lang="en">2018. 01 ~ 2021. 05</h6>
                <h2 className="pb-3 fw-700"><span lang="en">Geora Tech </span>(조지아 공과대학교)</h2>
                <h4 className="fw-700" lang="en">B.S. Computational Media</h4>
                <h5 className="fw-700 fc-gray">세부전공: <span lang="en">Media & Experimental / Interaction Design</span></h5>
                <p className="fw-700 fc-gray w-lg-75 wb-keep-all"><span lang="en">UX </span>디자인: <span lang="en">UX/UI</span> 디자인, 인포그래픽, 그래픽 & 시각 디자인,
                디지털 디자인, 영상 프로덕션, 영화 제작</p>
                <p className="fw-700 fc-gray w-lg-75 wb-keep-all">컴퓨터 공학: 객체 지향 프로그래밍, 데이터 구조와 알고리즘, 이산 수학, 미디어 시스템 아키텍처, 
                  컴퓨터 그래픽, 데이터 시각화</p>
              </div>
              <div className="border-bottom border-2"></div>
              <div className="pt-5 pb-3 w-60">
                <h6 className="fw-700 fc-gray" lang="en">2013. 03 ~ 2016. 02</h6>
                <h2 className="fw-700 pb-3">유봉여자고등학교</h2>
                <h4 className="fw-700">고교과정 </h4>
              </div>
            </div>
          </div>
        </div>
        </AnimationOnScroll>
      </div>

      <div className="pt-4 bg-black" id="experience">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
      <div className="row mt-5" id="box">
            <div className="col-lg-5 mb-4 pe-lg-4 p-0">
              <div className="p-5 text-white r-24 box-black position-relative h-100">
                <img src={omlogo} width="60%" class="position-absolute bottom-0 end-0 pe-3 pb-3"/>
                <h5 className="mb-3 fw-700">경력</h5>
                <h6 className="fw-700 fc-gray" lang="en">2021. 06 ~ 2022. 01</h6>
                <h2 className="pb-3 fw-700" lang="en">1MILLION</h2>
                <h4 className="fw-700 pb-3" lang="en">PO</h4>
                <ul>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">원밀리언 리브랜딩 후, 신규 웹사이트와 애플리케이션 수정 및 운영</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">신규 글로번 댄스 플랫폼 <span lang="en">5six7eight</span> 기획 및 제작 (신규 기획 ~ 내부 사용성 테스트)</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">신규 <span lang="en">WebOS</span> 애플리케이션 <span lang="en">'1M HomeDance'</span>의 웹사이트 기획 및 디자인</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">신규 프로젝트 <span lang="en">'1M Workshop'</span>의 반응형 웹사이트 기획 및 디자인</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">웹 스크럼팀을 리드하면 스프린트 진행</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">원밀리언 프로덕트 제작팀 운영체계 구축</h5></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 text-start mb-4 p-0">
              <div className="p-4 p-md-5 pb-3 text-white r-24 box-black position-relative">
                <img src={ruaclogo} width="25%" class="position-absolute bottom-0 end-0 pe-3 pb-3"/>
                <h6 className="fw-700 fc-gray" lang="en">2019. 01 ~ 2021. 05</h6>
                <h2 className="pb-3 fw-700" lang="en">Rise Up & Care</h2>
                <h4 className="fw-700 pb-3" lang="en">UI/UX Designer (volunteer)</h4>
                <ul>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">동화책 <span lang="en">'Scout Hero Search'</span> 시리즈 웹사이트 제작 및 운영</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">책 <span lang="en">'Reimagining Global Philanthropy'</span> 웹사이트 제작</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all"><span lang="en">Rise Up & Care</span> 홍보 비디오 제작</h5></li>
                </ul>
              </div>
              <div className="p-4 p-md-5 pb-3 mt-4 text-white rounded r-24 box-black position-relative">
                <img src={gtlogo} width="25%" class="position-absolute bottom-0 end-0 pe-3 pb-3"/>
                <h6 className="fw-700 fc-gray" lang="en">2019.01 ~ 2019.05</h6>
                <h2 className="pb-3 fw-700" lang="en">Georgia Tech College of Computing</h2>
                <h4 className="fw-700 pb-3"><span lang="en">CS 1315</span> 학부생 조교</h4>
                <ul>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">매주 주 1회 75분 수업 진행</h5></li>
                  <li className="pb-2"><h5 className="fw-60 wb-keep-all0">매주 4 ~ 5시간 오피스 아워를 열어 학생들을 도움</h5></li>
                  <li className="pb-2"><h5 className="fw-600 wb-keep-all">시험 문제 출제</h5></li>
                </ul>
              </div>
            </div>
          </div>
      </AnimationOnScroll>
      </div>

      
      <div className="pt-4 pb-4 bg-black" id="skill">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <div className="p-lg-4 p-md-3 p-2 my-5 text-white r-24 box-black" id="box">
          <div className="row mx-2 mt-3">
            <div className="col-lg-5 text-start pe-5 mb-4">
              <h5 className="pb-2 fw-700">기술</h5>
              <h4 className="w-60 fw-700">다양한 디자인 툴부터 <br></br>협업 툴까지 완벽 그 자체.</h4>
            </div>
            <div className="col-lg-7 text-start">
              <h6 className="fw-700 fc-gray">각 카테고리를 선택해보세요.</h6>
              <Tabs
                defaultActiveKey="design"
                id="categoryTab"
                className="mb-4"
              >
                <Tab eventKey="design" title="디자인">
                  <div className="w-md-60" id="design">
                    <Progress itemArr={ skillListD } />
                  </div>
                </Tab>
                <Tab title=" / " disabled></Tab>
                <Tab eventKey="programming" title="프로그래밍">
                  <div className="w-md-60" id="programming">
                    <Progress itemArr={ skillListP } />
                  </div>
                </Tab>
                <Tab title=" / " disabled></Tab>
                <Tab eventKey="communication" title="커뮤니케이션">
                <div id="communication">
                  <div className="w-md-60">
                    <h5 className="fw-600 pb-2" lang="en">Software</h5>
                    <Progress itemArr={ skillListC } />
                  </div>
                  <div className="w-md-75 my-5 border-bottom"></div>
                  <div className="w-md-60">
                    <h5 className="fw-600 pb-2" lang="en">Language</h5>
                    <Progress itemArr={ skillListL } />
                  </div>
                </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        </AnimationOnScroll>
      </div>

      <div className="pt-4 pb-4 mb-5 text-center" id="case-study">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <h6 className="fw-600 mt-5" lang="en">Case Study</h6>
        <h1 className="display-3 fw-700 pb-3 blue-gradient">이제는 증명할 시간.</h1>
        <p className="mx-5 mb-0 fw-600">아래에서 마음에 드는 것을 골라 탐구해보세요.</p>
        <p className="mx-5 fw-600">신규 서비스 기획/디자인부터 스크럼 팀 리드까지 저의 작업 과정, 스타일 등을 심층 탐구 할 수 있습니다.</p>
        <Row xs={1} md={3} className="g-4 mt-4">
        <CardCol link="/5six7eight" imgSrc={ card5678 } title="5six7eight Creative Studio"
        text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
        <CardCol link="/1m-workshop" imgSrc={ card1mws } title="1M Workshop"
        text="기존의 워크샵 예약 FLOW를 보완한 어떤 워크샵 이벤트에서도 사용할 수 있는 Universal All-in-1 웹사이트를 소개합니다." />
        <CardCol link="/1m-homedance" imgSrc={ card1mhd } title="1M HomeDance"
        text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
        </Row>
        </AnimationOnScroll>
      </div>
      </div>
      <Footer bgColor="black" textColor="white" />
    </main>
  )
}

export default IndexPage
